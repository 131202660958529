import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IconTime } from '../../../components';
import { DayOfWeekInternal, TriggerPriceAndTimeConditionResponse } from '../../../data-access/gql-types/graphql';
import { sortedDays } from '../../../utils/helpers';
import ActionConditionItemDetails from '../condition-item-details';

interface PropsInterface {
  priceCondition: TriggerPriceAndTimeConditionResponse;
  removePriceCondition?: () => void;
  handlePriceConditionClick?: () => void;
}

export const PriceConditionDetails: React.FC<PropsInterface> = ({
  priceCondition,
  removePriceCondition,
  handlePriceConditionClick,
}) => {
  const { t } = useTranslation('action');

  const shortDayOfWeek = (day: DayOfWeekInternal) => t(`trigger.shortenedDaysOfWeek.${day.toLowerCase()}`);

  const condition = useMemo(() => {
    return {
      icon: <IconTime size={30} />,
      title: t('trigger.type.price.header'),
    };
  }, [priceCondition]);

  const repeatOption = useMemo(() => {
    if (priceCondition.betweenHoursFromInclusive && priceCondition.betweenHoursToInclusive)
      return `${t('trigger.priceConditionOptions.hours')}: ${priceCondition.betweenHoursFromInclusive}:00-${
        priceCondition.betweenHoursToInclusive
      }:00`;
    if (priceCondition.numberOfCheapestPeriodsInDay)
      return `${t('trigger.priceConditionOptions.cheapestPeriods')}: ${priceCondition.numberOfCheapestPeriodsInDay}`;
    if (priceCondition.priceGreaterThanOrEqual)
      return `${t('trigger.priceConditionOptions.priceGreater')}: ${priceCondition.priceGreaterThanOrEqual} zł/kWh`;
    if (priceCondition.priceLesserThanOrEqual)
      return `${t('trigger.priceConditionOptions.priceLesser')}: ${priceCondition.priceLesserThanOrEqual} zł/kWh`;

    if (priceCondition.daysOfWeek?.length) {
      return [...priceCondition.daysOfWeek]
        ?.sort((a, b) => sortedDays[a] - sortedDays[b])
        .map((day) => shortDayOfWeek(day))
        .join(', ');
    } else if (priceCondition.targetExecutionCount === 1) {
      return t('trigger.conditions.time.repeat.once');
    } else {
      return t('trigger.conditions.time.repeat.daily');
    }
  }, [priceCondition]);

  const handleClick = () => {
    if (handlePriceConditionClick) {
      handlePriceConditionClick();
    }
  };

  return (
    <ActionConditionItemDetails
      removeCondition={removePriceCondition}
      handleConditionClick={handleClick}
      title={condition.title}
      icon={condition.icon}
      repeatOption={repeatOption}
    />
  );
};
